var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-10",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"header mb-2 pl-2 py-4",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"24px","font-weight":"400"}},[_vm._v(_vm._s(_vm.$t("non_im.stuexchange")))])]),_c('v-card',{staticClass:"pa-4",attrs:{"outlined":"","width":"100vw"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"search","solo":"","dense":""}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"dark":"","color":"#de5c8e"},on:{"click":function($event){return _vm.opendialog()}}},[_vm._v("เพิ่มExchange/Visitting Student")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.EngLang)?_c('v-data-table',{attrs:{"headers":_vm.headersEN,"items":_vm.items},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name_of_user))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertDate(item.createdAt))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status == 'Waiting for verification'
                ? 'orange'
                : item.status == 'Request verified'
                ? 'blue'
                : item.status == 'Approved'
                ? 'green'
                : item.status == 'Pending'
                ? 'orange'
                : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.goToEdit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteItem(item.id)}}},[_vm._v("mdi-delete")])]}}],null,true)}):_vm._e(),(!_vm.EngLang)?_c('v-data-table',{attrs:{"headers":_vm.headersTH,"items":_vm.items},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertDate(item.createdAt))+" ")])]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status == 'Waiting for verification'
                ? 'orange'
                : item.status == 'Request verified'
                ? 'blue'
                : item.status == 'Approved'
                ? 'green'
                : item.status == 'Pending'
                ? 'orange'
                : 'red',"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.goToEdit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteItem(item.id)}}},[_vm._v("mdi-delete")])]}}],null,true)}):_vm._e()],1)],1),_c('Dialog',{attrs:{"dialog":_vm.dialog,"isEdit":_vm.isEdit,"form":_vm.form},on:{"closeDialog":function($event){return _vm.closeDialog()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }